<template>
  <div class="homepage">
    <div class="homepage_tit">最新资讯</div>
    <div class="homepage_one">
      <div class="homepage_one_l" @click="toVideo">
        <img :src="realTimeInfo.image" alt="" v-if="realTimeInfo.image" />
        <div class="homepage_one_l_txtImg">
          <div>{{ realTimeInfo.title }}</div>
          <div class="el-icon-video-play icons"></div>
        </div>
      </div>
      <div class="homepage_one_r">
        <div
          class="homepage_one_r_item"
          v-for="(item, index) in realTimeList"
          :key="index"
          @click="toTxtDetail(item)"
        >
          <img :src="item.image" alt="" />
          <div class="homepage_one_r_item_r">
            <div class="homepage_one_r_item_r_tit">
              {{ item.title }}
            </div>
            <div class="">
              阅读数：{{ item.read_num }}次<span
                style="width: 10px; display: inline-block"
              >
              </span
              >{{ item.createtime }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="more_btn" @click="toUrl('/Index/routerboy3', '4')">
      查看更多 >>
    </div>
    <img class="characteristic" :src="homeInfo.specializ_img3" alt="" />

    <div class="homepage_tit">产品介绍</div>
    <div class="home_introduce">
      <div
        class="home_introduce_item"
        v-for="(item, index) in introduceList"
        :key="index"
      >
        <img :src="item.image" alt="" />
        <div class="home_introduce_item_txt">
          <div class="home_introduce_item_txt_1">{{ item.name }}</div>
          <div class="home_introduce_item_txt_2">
            {{ item.introduce }}
          </div>
          <div
            class="home_introduce_item_txt_3"
            @click="toContentDetail(item.id)"
          >
            查看详情
          </div>
        </div>
      </div>
      <div class="home_introduce_item" style="margin: 0; height: 0"></div>
      <div class="home_introduce_item" style="margin: 0; height: 0"></div>
      <div class="home_introduce_item" style="margin: 0; height: 0"></div>
    </div>
    <div class="more_btn" @click="toUrl('/Index/routerboy2', '3')">
      查看更多 >>
    </div>

    <div class="lunbo_box">
      <div class="homepage_tit">合作案例</div>
      <div class="lunbo" @mouseenter="on_bot_enter" @mouseleave="on_bot_leave">
        <swiper
          ref="mySwiper"
          :options="swiperOptions"
          v-if="cooperationList.length > 0"
        >
          <swiper-slide
            v-for="(item, index) in cooperationList"
            :key="index"
            style="
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              box-shadow: 0px 2px 8px 0px rgba(153, 153, 153, 0.23);
              background: #fff;
              margin: 10px 0;
              padding: 20px 0 38px;
            "
          >
            <!-- rgb(153 153 153) 0px 2px 8px 0px -->
            <img :src="item.logo" style="width: 185px; height: 60px" alt="" />
            <div style="margin: 0 50px; color: #303133; font-size: 16px">
              {{ item.desc }}
            </div>
            <div style="display: flex; color: #303133; font-size: 16px">
              <div style="margin-right: 20px">{{ item.name }}</div>
              <div>{{ item.author }}</div>
            </div>
          </swiper-slide>
          <!-- <div class="swiper-pagination" slot="pagination"></div> -->
          <div class="swiper-button-prev leftIcon" slot="button-prev"></div>
          <div class="swiper-button-next rightIcon" slot="button-next"></div>
        </swiper>
      </div>
      <!-- <div class="more_btn" @click="toUrl('/Index/routerboy4','4')">查看更多 >></div> -->
    </div>
    <el-dialog title=" " :visible.sync="dialogVisible" width="70%">
      <div style="text-align: center">
        <video :src="realTimeInfo.video" controls="controls"></video>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  information,
  casesList,
  product_intro,
  globalConfig,
} from "../../api/index";
export default {
  name: "homepage",
  components: {},
  data() {
    return {
      dialogVisible: false,
      activeName: "1",
      swiperOptions: {
        loop: true,
        slidesPerView: 3,
        spaceBetween: 30,
        centeredSlides: true,
        autoplay: true, //等同于以下设置
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        on: {
          resize: () => {
            this.$refs.swiper.$swiper.changeDirection(
              window.innerWidth <= 960 ? "vertical" : "horizontal"
            );
          },
        },
      },
      realTimeList: [],
      realTimeInfo: {},
      cooperationList: [],
      introduceList: [],
      homeInfo: {},
    };
  },
  created() {},
  mounted() {
    this.init();
  },
  methods: {
    toVideo() {
      console.log(this.realTimeInfo);
      this.dialogVisible = true;
    },
    //文章详情
    toTxtDetail(val) {
      console.log(val, "12");

      this.$store.commit("swicthTab", "4");
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      this.$router.push({
        path: "/Index/routerboy3?id=" + val.id,
      });
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    init() {
      information().then((res) => {
        this.realTimeList = res.data.data;
        this.realTimeInfo = res.data.info;
      });

      casesList().then((res) => {
        this.cooperationList = res.data;
      });

      product_intro().then((res) => {
        this.introduceList = res.data;
      });

      globalConfig().then((res) => {
        this.homeInfo = res.data;
      });
    },
    toUrl(val, index) {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      this.$router.push({
        path: val,
      });

      this.$store.commit("swicthTab", index);
    },
    toContentDetail(id) {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      this.$router.push({
        name: "homePageContent",
        params: {
          id: id,
        },
      });
    },

    on_bot_enter() {
      this.$refs.mySwiper.swiper.autoplay.stop();
    },
    on_bot_leave() {
      // this.$refs.mySwiper.swiper.autoplay.start();
    },
  },
};
</script>

<style lang="less" scoped>
.homepage {
  .homepage_tit {
    color: rgba(35, 35, 35, 100);
    font-size: 32px;
    text-align: center;
    padding: 60px 0 40px;
  }
  .more_btn {
    width: 180px;
    height: 50px;
    line-height: 50px;
    border-radius: 4px;
    background-color: rgba(45, 116, 248, 100);
    color: rgba(128, 128, 128, 100);
    font-size: 18px;
    text-align: center;
    color: #fff;
    margin: 60px auto;
  }
  .characteristic {
    width: 100%;
    min-width: 1300px;
  }
  .homepage_one {
    width: 1300px;
    margin: 0 auto;
    display: flex;

    .homepage_one_l {
      position: relative;
      width: 628px;
      height: 404px;
      margin-right: 40px;
      img {
        width: 100%;
        height: 100%;
      }
      .homepage_one_l_txtImg {
        display: flex;
        align-items: center;
        justify-content: space-between;
        line-height: 50px;
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.6);
        color: #fff;
        font-size: 24px;
        padding: 0 40px;
        box-sizing: border-box;
        .icons {
          font-size: 40px;
        }
      }
    }
    .homepage_one_r {
      flex: 1;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .homepage_one_r_item {
        display: flex;
        img {
          width: 188px;
          height: 120px;
          margin-right: 20px;
        }
        .homepage_one_r_item_r {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          color: rgba(172, 172, 172, 100);
          font-size: 16px;
          flex: 1;
          .homepage_one_r_item_r_tit {
            font-size: 20px;
            color: rgba(51, 51, 51, 100);
          }
        }
      }
    }
  }
  .home_introduce {
    width: 1300px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .home_introduce_item {
      width: 310px;
      height: 216px;
      margin-bottom: 20px;
      position: relative;
      > img {
        width: 100%;
        height: 100%;
      }
      .home_introduce_item_txt {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        text-align: center;
        padding: 30px 20px 43px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        opacity: 0;
        background: rgba(0, 0, 0, 0.6);
        .home_introduce_item_txt_1 {
          color: rgba(255, 255, 255, 100);
          font-size: 20px;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          line-clamp: 1;
          -webkit-box-orient: vertical;
        }
        .home_introduce_item_txt_2 {
          color: rgba(255, 255, 255, 100);
          font-size: 14px;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .home_introduce_item_txt_3 {
          border-radius: 100px;
          background-color: rgba(255, 255, 255, 100);
          color: rgba(45, 116, 248, 100);
          font-size: 14px;
          width: 100px;
          height: 36px;
          text-align: center;
          line-height: 36px;
          margin: 0 auto;
          cursor: pointer;
        }
      }
      &:hover {
        .home_introduce_item_txt {
          opacity: 1;
        }
      }
    }
  }
  .lunbo_box {
    background-image: url("../../assets/imgs/9.png");
    padding-bottom: 60px;
    background-size: 100% 100%;
    .lunbo {
      width: 1300px;
      margin: 0 auto;
      .swiper-container {
        width: 100%;
        height: 300px;
        padding-bottom: 30px;
      }
      .swiper-button-prev:after {
        font-size: 15px;
        color: #fff;
      }
      .swiper-button-next:after {
        font-size: 15px;
        color: #fff;
      }
      .leftIcon {
        background-color: #333;
        width: 30px;
        height: 30px;
        border-radius: 5px;
      }
      .rightIcon {
        background-color: #333;
        width: 30px;
        height: 30px;
        border-radius: 5px;
      }
      .leftIcon:hover {
        background-color: #2d74f8;
      }
      .rightIcon:hover {
        background-color: #2d74f8;
      }
      .swiper-button-prev {
        left: 10px;
      }
      .swiper-slide {
        text-align: center;
        font-size: 18px;
        // background: red;
        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
        transition: 300ms;
        transform: scale(0.8);
      }

      .swiper-slide-active,
      .swiper-slide-duplicate-active {
        transform: scale(1);
      }
    }
  }
}
</style>