<template>
  <div class="homepage">
    <div class="homepage-one">
      <div class="homepage-place">
        <img src="../../assets/imgs/s.png" alt="" class="homepage-icon" />
        <div
          style="
            margin-bottom: 50px;
            color: #2d74f8;
            font-size: 28px;
            font-weight: 900;
            width: 20px;
          "
        >
          {{ dataInfo.place }}
        </div>
        <div class="homepage-log">
          <div>茶友茗泉</div>
          <div>cha you min quan</div>
        </div>
        <img src="../../assets/imgs/x.png" alt="" class="homepage-icon" />
      </div>
      <div class="homepage-placeImg">
        <img :src="dataInfo.image" v-if="dataInfo.image" alt="" class="homepage-img" />
      </div>
      <div style="height: 615px">
        <div
          style="
            font-size: 32px;
            color: #333333;
            margin-bottom: 30px;
            line-height: 1;    text-align: center;
          "
        >
          {{ dataInfo.title }}
        </div>
        <div style="font-size: 20px; color: #333333; margin-bottom: 20px;    text-align: center;">
          {{ dataInfo.subtitle }}
        </div>
        <div
          class="homepage-text"
          style="color: #575757; font-size: 14px"
          v-html="dataInfo.content"
        ></div>
      </div>
    </div>
    <div style="">
      <img
        src="../../assets/imgs/sthj.png"
        alt=""
        style="height: 580px; width: 100%"
      />

      <div
        style="
          width: 1300px;
          margin: 0 auto;
          background-color: #fff;
          padding: 40px;
          position: relative;
          margin-top: -430px;
        "
      >
        <div
          v-for="(item, index) in ecologyList"
          :key="index"
          :class="index % 2 == 0 ? 'ecotope' : 'ecotope1'"
        >
          <div
            style="
              flex: 1;
              display: flex;
              justify-content: center;
              align-items: center;
              position: relative;
            "
          >
            <img
              v-if="index % 2 != 0"
              src="../../assets/imgs/ysj.png"
              alt=""
              style="
                margin-right: 26px;
                position: absolute;
                right: 95%;
                top: 50%;
                transform: translate(-50%, -50%);
                z-index: 99;
              "
            />
            <img
              :src="item.image"
              alt=""
              style="width: 508px; height: 275px; border-radius: 10px"
            />
            <img
              v-if="index % 2 == 0"
              src="../../assets/imgs/zsj.png"
              alt=""
              style="
                margin-left: 26px;
                position: absolute;
                left: 95%;
                top: 50%;
                transform: translate(-50%, -50%);
                z-index: 99;
              "
            />
          </div>

          <div
            style="
              background: #2d74f8;
              width: 1px;
              position: relative;
              margin: 0 66px;
            "
          >
            <div
              style="
                background-color: #b6cfff;
                opacity: 0.7;
                padding: 16px;
                position: absolute;
                border-radius: 50%;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                z-index: 99;
                filter: blur(1px);
              "
            >
              <div
                style="
                  width: 10px;
                  height: 10px;
                  display: flex;
                  border-radius: 50%;
                  background-color: #2878ff;
                  position: absolute;
                  left: 50%;
                  top: 50%;
                  transform: translate(-50%, -50%);
                  z-index: 99;
                "
              ></div>
            </div>
            <div style="border-right: 1px solid #2878ff;height：300px"></div>

            <div
              style="background: #fff; width: 1px; position: relative"
              :class="[
                index == 0 ? 'partingLine' : '',
                index == ecologyList.length - 1 ? 'partingLine1' : '',
              ]"
            >
              <!-- <div
                style="
                  position: absolute;
                  left: 50%;
                  top: 50%;
                  transform: translate(-50%, -50%);
                  z-index: 9999;
                "
              ></div> -->
            </div>
          </div>
          <div
            style="
              flex: 1;
              display: flex;
              justify-content: center;
              flex-direction: column;
            "
          >
            <div
              style="
                color: #3375e1;
                font-size: 24px;
                margin-bottom: 28px;
                font-weight: 700;
              "
            >
              {{ item.title }}
            </div>
            <div style="color: ##333333; font-size: 14px; line-height: 2;text-indent: 2em;">
              {{ item.content }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="position: relative">
      <img :src="ideaCont.image" style="width: 100%" alt="" />
      <div
        style="
          color: rgba(255, 255, 255, 100);
          font-size: 32px;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        "
      >
        {{ ideaCont.title }}
      </div>
    </div>
    <div
      style="
        background-color: #f7f7f7;
        padding: 30px 0 60px;
      "
    >
      <div
        style="width: 1300px; margin: 0 auto"
        v-html="ideaCont.content"
      ></div>
    </div>
  </div>
</template>

<script>
import { brandStory, environment, brandConcept } from "../../api/index";
export default {
  name: "homepage",
  components: {},
  data() {
    return {
      activeName: "1",
      list: [],
      dataInfo: {},
      ecologyList: [],
      ideaCont: {},
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    init() {
      brandStory().then((res) => {
        this.dataInfo = res.data;
      });

      environment().then((res) => {
        this.ecologyList = res.data;
      });

      brandConcept().then((res) => {
        this.ideaCont = res.data;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.homepage {
  .partingLine {
    height: 50%;
  }
  .partingLine1 {
    height: 50%;
    top: 50%;
  }
  .ecotope {
    display: flex;
  }
  .ecotope1 {
    display: flex;
    flex-direction: row-reverse;
  }
  .homepage-one {
    background-image: url("../../assets/imgs/ppgs.png");
    height: 780px;
    display: flex;
    justify-content: center;
    align-items: center;
    .homepage-icon {
      width: 60px;
      height: 60px;
    }

    .homepage-img {
      width: 460px;
      height: 615px;
    }

    .homepage-place {
      display: flex;
      flex-direction: column;
      width: 60px;
      justify-content: center;
      align-items: center;
    }

    .homepage-text {
      width: 730px;
      height: 490px;
    }

    .homepage-placeImg {
      margin-left: 20px;
      margin-right: 40px;
    }

    .homepage-log {
      transform: rotate(90deg);
      text-align: center;
      min-width: 160px;
      margin-bottom: 50px;
    }
  }
}
</style>