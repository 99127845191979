<template>
  <div class="homepage">
    <div class="homepage_tit">专业化生产</div>
    <div class="homepage_one">
      <div class="homepage_one_l">
        <img
          style="width: 520px; height: 520px"
          :src="homeInfo.specializ_img"
          v-if="homeInfo.specializ_img"
          alt=""
        />
        <img
          :src="homeInfo.specializ_img2"
          v-if="homeInfo.specializ_img2"
          alt=""
          style="width: 520px; margin-left: 70px"
        />
      </div>
    </div>
    <img
      class="characteristic"
      :src="homeInfo.specializ_img3"
      v-if="homeInfo.specializ_img3"
      alt=""
      style=""
    />

    <div class="lunbo_box">
      <div class="homepage_tit">产品展示</div>
      <div class="lunbo" @mouseenter="on_bot_enter" @mouseleave="on_bot_leave">
        <swiper
          ref="mySwiper"
          :options="swiperOptions"
          v-if="dataList.length > 0"
        >
          <swiper-slide v-for="(item, index) in dataList" :key="index">
            <div>
              <div>
                <img
                  :src="item.image"
                  alt=""
                  style="width: 100%; height: 300px"
                />
              </div>
              <div
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  background-color: #fff;
                  width: 100%;
                  color: #333333;
                  font-size: 20px;
                  padding: 10px 20px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                "
              >
                {{ item.name }}
              </div>
            </div>
          </swiper-slide>
          <div class="swiper-button-prev leftIcon" slot="button-prev"></div>
          <div class="swiper-button-next rightIcon" slot="button-next"></div>
        </swiper>
      </div>
    </div>

    <div class="homepage_tit">场景展示</div>
    <div class="home_introduce">
      <div
        class="home_introduce_item"
        v-for="(item, index) in sceneList"
        :key="index"
      >
        <img :src="item.image" alt="" />
        <div
          style="box-shadow: 0px 0px 5px #888888; padding: 51px 20px 47px 20px"
        >
          <div
            style="
              color: #333333;
              font-size: 20px;
              text-align: center;
              margin-bottom: 17px;
              text-overflow: -o-ellipsis-lastline;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              line-clamp: 1;
              -webkit-box-orient: vertical;
            "
          >
            {{ item.name }}
          </div>
          <div
            style="
              color: #575757;
              font-size: 14px;
              text-overflow: -o-ellipsis-lastline;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              line-clamp: 3;
              -webkit-box-orient: vertical;
              line-height: 22px;
            "
          >
            {{ item.introduce }}
          </div>
        </div>
      </div>
      <div style="width: 420px;"></div>
      <div style="width: 420px;"></div>
      <div style="width: 420px;"></div>
    </div>

    <el-pagination
    style="text-align: center; margin-bottom: 30px;"
          background
          layout="prev, pager, next"
          :total="total"
          :page-size="9"
          @next-click="nextClick"
          @prev-click="prevClick"
          @current-change="sizeChange"
        >
        </el-pagination>
  </div>
</template>

<script>
import { productSpecs, productScene, globalConfig } from "../../api/index";
export default {
  name: "homepage",
  components: {},
  data() {
    return {
      activeName: "1",
      swiperOptions: {
        loop: true,
        slidesPerView: 4,
        spaceBetween: 30,
        freeMode: true,
        autoplay: true, //等同于以下设置
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        on: {
          resize: () => {
            this.$refs.swiper.$swiper.changeDirection(
              window.innerWidth <= 960 ? "vertical" : "horizontal"
            );
          },
        },
      },
      dataList: [],
      sceneList: [],
      homeInfo: {},
      page:1,
      total:1,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    on_bot_enter() {
      this.$refs.mySwiper.swiper.autoplay.stop();
    },
    on_bot_leave() {
      this.$refs.mySwiper.swiper.autoplay.start();
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    init() {
      productSpecs().then((res) => {
        this.dataList = res.data;
      });
      let data ={
        page:this.page,
      }
      productScene(data).then((res) => {
        this.sceneList = res.data.data;
        this.total = res.data.total;
      });

      globalConfig().then((res) => {
        this.homeInfo = res.data;
      });
    },

    nextClick(val){
let data ={
        page:val,
      }
      productScene(data).then((res) => {
        this.sceneList = res.data.data;
        this.total = res.data.total;
      });
    },
    prevClick(val){
      let data ={
        page:val,
      }
      productScene(data).then((res) => {
        this.sceneList = res.data.data;
        this.total = res.data.total;
      });
    },
    sizeChange(val){
      console.log(val,'123');
      let data ={
        page:val,
      }
      productScene(data).then((res) => {
        this.sceneList = res.data.data;
        this.total = res.data.total;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.homepage {
  .home_introduce {
    width: 1300px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 30px;
    .home_introduce_item {
      width: 420px;
      height: 315px;
      margin-bottom: 200px;
      position: relative;
      line-height: 1;
      > img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .lunbo_box {
    background-image: url("../../assets/imgs/dzgg.png");
    padding-bottom: 60px;
    background-size: 100% 100%;

    .lunbo {
      width: 1300px;
      margin: 0 auto;
      .swiper-container {
        width: 100%;
        height: 380px;
      }
      .swiper-button-prev:after {
        font-size: 15px;
        color: #fff;
      }
      .swiper-button-next:after {
        font-size: 15px;
        color: #fff;
      }
      .leftIcon {
        background-color: #333;
        width: 30px;
        height: 30px;
        border-radius: 5px;
      }
      .rightIcon {
        background-color: #333;
        width: 30px;
        height: 30px;
        border-radius: 5px;
      }
      .leftIcon:hover {
        background-color: #2d74f8;
      }
      .rightIcon:hover {
        background-color: #2d74f8;
      }
      .swiper-button-prev {
        left: 10px;
      }
      // .swiper-slide {
      //   text-align: center;
      //   font-size: 18px;
      //   background: red;
      //   /* Center slide text vertically */
      //   display: -webkit-box;
      //   display: -ms-flexbox;
      //   display: -webkit-flex;
      //   display: flex;
      //   -webkit-box-pack: center;
      //   -ms-flex-pack: center;
      //   -webkit-justify-content: center;
      //   justify-content: center;
      //   -webkit-box-align: center;
      //   -ms-flex-align: center;
      //   -webkit-align-items: center;
      //   align-items: center;
      //   transition: 300ms;
      //   transform: scale(0.8);
      // }

      // .swiper-slide-active,
      // .swiper-slide-duplicate-active {
      //   // transform: scale(1);
      // }
    }
  }
  .swiper {
    width: 100%;
    height: 100%;
    overflow: hidden;
    .swiper-slide {
      text-align: center;
      font-size: 18px;
      background: red;
      position: relative;
    }
  }
  .homepage_tit {
    color: rgba(35, 35, 35, 100);
    font-size: 32px;
    text-align: center;
    padding: 60px 0 40px;
  }
  .characteristic {
    width: 100%;
    min-width: 1300px;
  }
  .homepage_one {
    width: 1300px;
    margin: 0 auto;
    display: flex;

    .homepage_one_l {
      display: flex;
      justify-content: center;
      align-items: center;
      .homepage_one_l_txtImg {
        display: flex;
        align-items: center;
        justify-content: space-between;
        line-height: 100px;
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.6);
        color: #fff;
        font-size: 24px;
        padding: 0 40px;
        box-sizing: border-box;
        .icons {
          font-size: 40px;
        }
      }
    }
    .homepage_one_r {
      flex: 1;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .homepage_one_r_item {
        display: flex;
        img {
          width: 188px;
          height: 120px;
          margin-right: 20px;
        }
        .homepage_one_r_item_r {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          color: rgba(172, 172, 172, 100);
          font-size: 16px;
          .homepage_one_r_item_r_tit {
            font-size: 20px;
            color: rgba(51, 51, 51, 100);
          }
        }
      }
    }
  }
}
</style>