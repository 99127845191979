<template>
  <div id="app">
    <Header />
    <router-view></router-view>
    <Bottom />
    <CeBian />

    <el-dialog
      title="留言"
      :visible.sync="dialogVisible"
      @close="close"
      width="500px"
    >
      <div class="msgContent">
        <div>
          <div>姓名:</div>
          <el-input
            class="ipts"
            size="mini"
            v-model="name"
            placeholder="请输入姓名"
          ></el-input>
        </div>
        <div>
          <div>联系方式:</div>
          <el-input
            class="ipts"
            size="mini"
            v-model="mobile"
            placeholder="请输入联系方式"
          ></el-input>
        </div>
        <div>
          <div>留言内容:</div>
          <el-input
            class="ipts"
            size="mini"
            type="textarea"
            v-model="content"
            placeholder="请输入内容"
          ></el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="close">取 消</el-button>
        <el-button size="mini" type="primary" @click="sutb">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Header from "@/components/header";
import Bottom from "@/components/PageMain/Bottom";
import CeBian from "@/components/CeBian";
import { message } from "../api/index";
export default {
  name: "App",
  components: {
    Header,
    Bottom,
    CeBian,
  },
  data() {
    return {
      name: "",
      mobile: "",
      content: "",
    };
  },
  computed: {
    dialogVisible() {
      this.name = "";
      this.mobile = "";
      this.content = "";
      return this.$store.state.showMessage;
    },
  },
  methods: {
    close() {
      this.$store.commit("closeMessage");
    },
    sutb() {
      let data = {
        name: this.name,
        mobile: this.mobile,
        content: this.content,
      };
      message(data).then((res) => {
        if (res.code == 1) {
          this.$message({
            message: "提交成功",
            type: "success",
          });
          this.$store.commit("closeMessage");
        } else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
        console.log(res);
      });
    },
  },
};
</script>

<style lang='less'>
.msgContent {
  > div {
    display: flex;
    margin: 10px 0;
    .ipts {
      flex: 1;
    }
    > div {
      width: 100px;
    }
  }
}
</style>
