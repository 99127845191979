<template>
  <div class="homepage">
    <div class="introduction">
      <div>
        <img
          :src="dataInfo.image"
          v-if="dataInfo.image"
          alt=""
          style="width: 630px; height: 400px; margin-right: 40px"
        />
      </div>
      <div style="display: flex;flex-direction: column;justify-content: space-between;">
        <div
          style="
            color: #333333;
            font-size: 20px;
            font-weight: 800;
            margin-bottom: 20px;
                text-align: center;
          "
        >
          {{ dataInfo.name }}
        </div>
        <div
          style="color: #575757; font-size: 14px"
          v-html="dataInfo.content"
        ></div>
      </div>
    </div>
    <div class="agency">
      <div style="display: flex; justify-content: center; margin-bottom: 50px">
        <div
          style="
            border: 2px solid #fff;
            padding: 27px 65px;
            color: #fff;
            display: flex;
            flex-direction: column;
            align-items: center;
          "
        >
          <div style="font-size: 25px">{{ dataInfo.case_num }}</div>
          <div style="font-size: 14px; width: 58px">海拔高度</div>
        </div>
        <div
          style="
            border: 2px solid #fff;
            padding: 27px 65px;
            margin: 0 43px;
            color: #fff;
            display: flex;
            flex-direction: column;
            align-items: center;
          "
        >
          <div style="font-size: 25px">{{ dataInfo.team_num }}</div>
          <div style="font-size: 14px; width: 72px">森林总面积</div>
        </div>
        <div
          style="
            border: 2px solid #fff;
            padding: 27px 65px;
            color: #fff;
            display: flex;
            flex-direction: column;
            align-items: center;
          "
        >
          <div style="font-size: 25px">{{ dataInfo.case_ratio }}</div>
          <div style="font-size: 14px; width: 72px">森林覆盖率</div>
        </div>
      </div>
      <div style="color: #fff; font-size: 14px; width: 1000px; margin: 0 auto">
        {{ dataInfo.achievement }}
      </div>
    </div>
    <div class="certification">
      <div style="color: #333333; font-size: 14px;text-indent: 30px;">
        {{ dataInfo.advantage }}
      </div>
    </div>

    <div class="lunbo_box">
      <div class="homepage_tit">荣誉资质</div>
      <div class="lunbo" @mouseenter="on_bot_enter" @mouseleave="on_bot_leave">
        <swiper
          ref="mySwiper"
          :options="swiperOptions"
          v-if="honorList.length > 0"
        >
          <swiper-slide v-for="(item, index) in honorList" :key="index">
            <div>
              <div style="height: 300px; position: relative">
                <img
                  :src="item.image"
                  alt=""
                  style="
                    max-width: 300px;
                    max-height: 300px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                  "
                />
              </div>
              <!-- <div
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  background-color: #fff;
                  width: 100%;
                  color: #333333;
                  font-size: 20px;
                  padding: 10px 20px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                "
              >
                {{ item.name }}
              </div> -->
            </div>
          </swiper-slide>
          <div class="swiper-button-prev leftIcon" slot="button-prev"></div>
          <div class="swiper-button-next rightIcon" slot="button-next"></div>
        </swiper>
      </div>
    </div>

    <div
      style="
        width: 1300px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0 auto;
        margin-bottom: 60px;
      "
    >
      <div
        v-for="(item, index) in companyList"
        :key="index"
        style="
          padding: 30px;
          display: flex;
          justify-content: center;
          box-shadow: 0px 2px 15px 0px  rgba(153,153,153,0.23);
          margin: 15px;
          flex:1;
        "
      >
        <div style="margin-right: 20px">
          <img :src="item.image" alt="" style="width: 210px; height: 160px" />
        </div>
        <div style="display: flex;flex-direction: column;justify-content: space-between;">
          <div style="display: flex; align-items: center;">
            <div>
              <img :src="item.logo" alt="" style="width: 40px;" />
            </div>
            <div style="margin-left: 17px">{{ item.title }}</div>
          </div>
          <div >电话：{{ item.mobile }}</div>
          <div >邮箱：{{ item.email }}</div>
          <div>地址：{{ item.address }}</div>
        </div>
      </div>
    </div>

    <div class="contactUs">
      <div style="text-align: center; color: #ffffff; font-size: 32px">
        联系我们
      </div>
      <div style="display: flex; justify-content: center; margin: 60px auto 0;width: 1300px;">
        <div
          style="
            background: rgba(249, 249, 249, 0.18);
            padding: 30px 34px;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-right: 10px;
            height: 160px;
            flex:1;
          "
        >
          <div style="margin-bottpm: 34px">
            <img
              src="../../assets/imgs/dw.png"
              alt=""
              style="width: 22px; height: 25px"
            />
          </div>

          <div
            style="
              text-align: center;
              flex-grow: 1;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #fff;
              font-size: 16px;
            "
          >
            {{ homeInfo.cp_address }}
          </div>
        </div>

        <div
          style="
            background: rgba(249, 249, 249, 0.18);
            padding: 30px 34px;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-right: 10px;
            flex:1;
            height: 160px;
          "
        >
          <div style="margin-bottpm: 34px">
            <img
              src="../../assets/imgs/qq.png"
              alt=""
              style="width: 22px; height: 25px"
            />
          </div>

          <div
            style="
              text-align: center;
              flex-grow: 1;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #fff;
              font-size: 16px;
            "
          >
            QQ：{{ homeInfo.cp_qq }} <br />邮编：{{ homeInfo.post_code }}
          </div>
        </div>

        <div
          style="
            background: rgba(249, 249, 249, 0.18);
            padding: 30px 34px;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-right: 10px;
            flex:1;
            height: 160px;
          "
        >
          <div style="margin-bottpm: 34px">
            <img
              src="../../assets/imgs/dh.png"
              alt=""
              style="width: 22px; height: 25px"
            />
          </div>

          <div
            style="
              text-align: center;
              flex-grow: 1;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #fff;
              font-size: 16px;
            "
          >
            服务热线:{{ homeInfo.cp_phone }}
          </div>
        </div>

        <div
          style="
            background: rgba(249, 249, 249, 0.18);
            padding: 30px 34px;
            display: flex;
            flex-direction: column;
            align-items: center;
            flex:1;
            height: 160px;
          "
        >
          <div style="margin-bottpm: 34px">
            <img
              src="../../assets/imgs/yx.png"
              alt=""
              style="width: 22px; height: 25px"
            />
          </div>

          <div
            style="
              text-align: center;
              flex-grow: 1;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #fff;
              font-size: 16px;
            "
          >
            {{ homeInfo.cp_email }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { aboutUs, honorary, company, globalConfig } from "../../api/index";
export default {
  name: "homepage",
  components: {},
  data() {
    return {
      activeName: "1",
      swiperOptions: {
        slidesPerView: 4,
        spaceBetween: 30,
        freeMode: true,
        autoplay: true, //等同于以下设置
        loop: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        on: {
          resize: () => {
            this.$refs.swiper.$swiper.changeDirection(
              window.innerWidth <= 960 ? "vertical" : "horizontal"
            );
          },
        },
      },
      dataInfo: {},
      honorList: [],
      companyList: [],
      homeInfo: {},
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    on_bot_enter() {
      this.$refs.mySwiper.swiper.autoplay.stop();
    },
    on_bot_leave() {
      this.$refs.mySwiper.swiper.autoplay.start();
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    init() {
      aboutUs().then((res) => {
        this.dataInfo = res.data;
      });

      honorary().then((res) => {
        this.honorList = res.data;
      });

      company().then((res) => {
        this.companyList = res.data;
      });

      globalConfig().then((res) => {
        this.homeInfo = res.data;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.homepage {
  background: #f7f7f7;
  .homepage_tit {
    color: rgba(35, 35, 35, 100);
    font-size: 32px;
    text-align: center;
    padding: 60px 0 40px;

  }
  .lunbo_box {
    width: 1300px;
  margin: 0 auto;
    // background-image: url("../../assets/imgs/dzgg.png");
    padding-bottom: 60px;
    .lunbo {
      width: 1300px;
      margin: 0 auto;
      .swiper-container {
        width: 100%;
        height: 300px;
      }
      .swiper-button-prev:after {
        font-size: 15px;
        color: #fff;
      }
      .swiper-button-next:after {
        font-size: 15px;
        color: #fff;
      }
      .leftIcon {
        background-color: #333;
        width: 30px;
        height: 30px;
        border-radius: 5px;
      }
      .rightIcon {
        background-color: #333;
        width: 30px;
        height: 30px;
        border-radius: 5px;
      }
      .leftIcon:hover {
        background-color: #2d74f8;
      }
      .rightIcon:hover {
        background-color: #2d74f8;
      }
      .swiper-button-prev {
        left: 10px;
      }
      // .swiper-slide {
      //   text-align: center;
      //   font-size: 18px;
      //   background: red;
      //   /* Center slide text vertically */
      //   display: -webkit-box;
      //   display: -ms-flexbox;
      //   display: -webkit-flex;
      //   display: flex;
      //   -webkit-box-pack: center;
      //   -ms-flex-pack: center;
      //   -webkit-justify-content: center;
      //   justify-content: center;
      //   -webkit-box-align: center;
      //   -ms-flex-align: center;
      //   -webkit-align-items: center;
      //   align-items: center;
      //   transition: 300ms;
      //   transform: scale(0.8);
      // }

      // .swiper-slide-active,
      // .swiper-slide-duplicate-active {
      //   // transform: scale(1);
      // }
    }
  }
}
.introduction {
  display: flex;
  justify-content: center;
  // align-items: center;
  padding: 60px 0;
  width: 1300px;
  margin: 0 auto;
}
.agency {
  background-image: url("../../assets/imgs/js1.png");
  padding: 68px 0 132px;
  width: 100%;
}
.certification {
  background-color: #fff;
  width: 1300px;
  margin: 0 auto;
  padding: 40px;
  box-shadow: 0px 2px 15px 0px rgba(153,153,153,0.23);
  transform: translateY(-60px);
}
.contactUs {
  background-image: url("../../assets/imgs/js2.jpg");
  padding:50px 0
  // display: flex;
  // justify-content: center;
  // align-items: center;
}
</style>