<template>
  <div class="homepage">
    <div class="left-content">
      <div class="left-content-title">新闻资讯</div>
      <div>
        <div
          v-for="(item, index) in titleList"
          :key="index"
          class="left-content-tabs"
          @click="activeTabs(item.id)"
          style="cursor: pointer;    font-size: 16px;"
        >
          <span :class="item.id == tabsId ? 'tabColor' : ''">{{
            item.name
          }}</span>
        </div>
      </div>
      <div style="margin-top: 150px">
        <div style="color: #333333; font-size: 24px; font-weight: 800">
          联系方式
        </div>
        <div style="margin-top: 20px; color: #575757; font-size: 16px">
          咨询热线：{{homeInfo.cp_phone}}
        </div>
        <div style="margin-top: 20px; color: #575757; font-size: 16px">
          公司地址：{{homeInfo.cp_address}}
        </div>
        <div style="margin-top: 30px" @click="toMsg">
          <el-button
            style="width: 100%; color: #2878ff; border: 1px solid #2878ff"
            >在线留言</el-button
          >
        </div>
      </div>
    </div>
    <div class="right-content" v-if="rightCenterType == 1">
      <div>
        <div
          class="right-content-card"
          v-for="(item, index) in lists"
          :key="index"
          @click="cardDetail(item)"
        >
          <div>
            <img
              v-if="item.type == 1"
              :src="item.image"
              alt=""
              style="width: 200px; height: 150px"
            />

            <video
              v-if="item.type == 2"
              :src="item.video"
              alt=""
              controls="controls"
              style="width: 200px; height: 150px"
            ></video>
          </div>
          <div style="margin-top: 2px; margin-left: 20px">
            <div
              style="
                color: #333333;
                font-size: 20px;
                margin-bottom: 12px;
                font-weight: 700;
              "
            >
              {{ item.title }}
            </div>
            <div style="color: #808080; font-size: 14px; margin-bottom: 17px">
              {{ item.introduct }}
            </div>
            <div>
              <span>阅读数：{{ item.read_num }}次</span><span> | </span
              ><span>{{ item.createtime }}</span>
            </div>
          </div>
        </div>
      </div>

      <div
        style="
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          :page-size="6"
          @next-click="nextClick"
          @prev-click="prevClick"
          @current-change="sizeChange"
        >
        </el-pagination>
      </div>
    </div>
    <div class="right-detail-content" v-if="rightCenterType == 2">
      <div class="detail-content">
        <div>
          <div class="right-content-title">
            {{ articleDetail.title }}
          </div>

          <div
            style="
              display: flex;
              justify-content: center;
              margin-bottom: 30px;
              color: #808080;
            "
          >
            <span>阅读数：{{ articleDetail.read_num }}次</span><span>  <span style="margin:0 4px;">|</span> </span
            ><span>{{ articleDetail.createtime }}</span>
          </div>
        </div>

        <div>
          <video
            v-if="articleDetail.video"
            :src="articleDetail.video"
            alt=""
            controls="controls"
            style="width: 760px; height: 420px; margin-bottom: 25px"
          ></video>
        </div>
        <div
          style="margin-bottom: 65px; color: #575757; font-size: 16px"
          v-html="articleDetail.content"
        ></div>
      </div>

      <div style="margin-bottom: 40px">
        <el-button
          style="color: #fff; font-size: 18px; background-color: #2878ff"
          @click="backCardType"
          >返回列表</el-button
        >
      </div>
      <div
        style="
          margin-bottom: 20px;
          color: #333333;
          font-size: 18px;
          cursor: pointer;
        "
        @click="nextDetail(articleDetail.nave.infoDow.id)"
        v-if="articleDetail.nave.infoDow"
      >
        下一篇：{{articleDetail.nave.infoDow.title}}
      </div>
      <div
        style="color: #333; font-size: 18px; cursor: pointer"
        @click="prevDetail(articleDetail.nave.infoUp.id)"
        v-if="articleDetail.nave.infoUp"
      >
        上一篇：{{articleDetail.nave.infoUp.title}}
      </div>
    </div>
  </div>
</template>

<script>
import { category, categoryList, categoryListDetail ,globalConfig} from "../../api/index";
export default {
  name: "homepage",
  components: {},
  data() {
    return {
      activeName: "1",
      titleList: [],
      tabsId: 1,
      rightCenterType: 1, //1是列表，2是详情
      page: 1,
      total: 10,
      lists: [],
      articleDetail: {
        nave:{
          infoDow:{},
          infoUp:{},
        }
      },
      homeInfo:{},
    };
  },
  mounted() {
    this.init();
    if(this.$route.query.id){
      console.log(this.$route.query.id);
      this.cardDetail({id:this.$route.query.id})
    }
  },
  methods: {
    toMsg(){
      this.$store.commit("switchshowMessage");
    },
    init() {
      category().then((res) => {
        this.titleList = res.data;
        this.tabsId = res.data[0].id;
        this.initList();
      });

      globalConfig().then((res) => {
        this.homeInfo = res.data;
      });
    },
    initList() {
      let data = {
        cate_id: this.tabsId,
        page: this.page,
        limit: 6,
        keyword: "",
      };
      categoryList(data).then((res) => {
        this.total = res.data.total;
        this.lists = res.data.data;
      });
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    nextClick(e) {
      this.page = e;
      this.initList();
    },
    prevClick(e) {
      this.page = e;
      this.initList();
    },
    sizeChange(e) {
      this.page = e;
      this.initList();
    },
    activeTabs(id) {
      this.page = 1;
      this.tabsId = id;
      this.rightCenterType = 1;
      this.initList();
    },
    cardDetail(item) {
      this.rightCenterType = 2;

      let data = {
        id: item.id,
      };
      // this.articleDetail = {}
        categoryListDetail(data).then((res) => {
          console.log(res,'123456');
          this.articleDetail = res.data;
        });
    },
    nextDetail(id) {
      console.log("下一篇详情");
      this.cardDetail({id:id})
    },
    prevDetail(id) {
      console.log("上一篇详情");
      this.cardDetail({id:id})
    },
    backCardType() {
      this.rightCenterType = 1;
    },
  },
};
</script>

<style lang="less" scoped>
.homepage {
  display: flex;
  padding: 20px 0 50px;
  width: 1300px;
  margin: 0 auto;
}
.tabColor {
  color: #2878ff;
}
.left-content-tabs {
  margin-bottom: 15px;
  &:hover{
    color: #2878ff;
  }
}
.left-content {
  padding: 60px 0 0 30px;
  margin-right: 30px;
  width: 234px;
}
.left-content-title {
  border-bottom: 1px dashed #e8e8e8;
  padding: 0 0 20px 0;
  margin-bottom: 20px;
  font-size: 24px;
  color: #333333;
  font-weight: 800;
}
.right-content-title {
  margin-bottom: 20px;
  font-size: 28px;
  color: #333333;
  font-weight: 800;
}
.right-content {
  padding: 40px 10px 40px 50px;
  flex: 1;
}
.right-detail-content {
  padding: 60px 30px 45px 30px;
  flex: 1;
}
.detail-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.right-content-card {
  display: flex;
  padding: 10px 10px 10px 30px;
  cursor: pointer;
}
</style>