<template>
  <div class="home-tool-bar">
    <!-- <a href="" class="item1 item-image">
      <div class="icon">
        <img src="../assets/imgs/4.png" alt="" class="static" />
        <img src="../assets/imgs/10.png" alt="" class="hover" />
      </div>
      <span class="text">官方微信</span>
      <div class="pop-content">
        <img :src="homeInfo.qr_code" alt="" />
      </div>
    </a> -->
    <div href="" class="item1 item-image">
      <div class="icon">
        <img src="../assets/imgs/4.png" alt="" class="static" />
        <img src="../assets/imgs/10.png" alt="" class="hover" />
      </div>
      <span class="text">小程序</span>
      <div class="pop-content">
        <img :src="homeInfo.wxapp_code" alt="" />
        <!-- <span class="desc">扫码领取新人百元礼包</span> -->
      </div>
    </div>
    <div href="" class="item1 item-image">
      <div class="icon">
        <img src="../assets/imgs/11.png" alt="" class="static" />
        <img src="../assets/imgs/13.png" alt="" class="hover" />
      </div>
      <span class="text">招商热线</span>
      <div class="pop-content">
        <!-- <img src="../assets/imgs/wx-img.png" alt=""> -->
        <span class="desc " style="margin-top:0;font-size:18px;padding-right:10px;">{{ homeInfo.cp_mobile }}</span>
      </div>
    </div>
    <div class="item1 item-image" @click="showMsg">
      <div class="icon">
        <img src="../assets/imgs/12.png" alt="" class="static" />
        <img src="../assets/imgs/14.png" alt="" class="hover" />
      </div>
      <span class="text">留言</span>
      <!-- <div class="pop-content">
          <img src="../assets/imgs/wx-img.png" alt="">
          <span class="desc">扫码领取新人百元礼包</span>
        </div> -->
    </div>

    <!-- <a href="" class="item1 item-image" v-for="i in 4" :key="i">
        <div class="icon">
          <img src="../assets/imgs/right-icon.png" alt="" class="static">
          <img src="../assets/imgs/right-icon1.png" alt="" class="hover">
        </div>
        <span class="text">手机APP</span>
      </a> -->
    <a href="" class="item1" @click="returnTop">
      <div class="icon">
        <img src="../assets/imgs/right-icon.png" alt="" class="static" />
        <img src="../assets/imgs/right-icon1.png" alt="" class="hover" />
      </div>
      <span class="text">返回顶部</span>
    </a>

   
  </div>
</template>

<script>
import { globalConfig } from "../api/index";
export default {
  data() {
    return {
      isShow: false,
      homeInfo: {},
    };
  },
  created() {},
  mounted() {
    this.init();
  },
  methods: {
    returnTop() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    init() {
      globalConfig().then((res) => {
        this.homeInfo = res.data;
      });
    },
    showMsg(){
      this.$store.commit("switchshowMessage");
    },
  },
};
</script>

<style lang="less" scoped>
.home-tool-bar {
  position: fixed;
  top: 420px;
  right: 0;
  z-index: 99;
  font-size: 12px;
  .item1 {
    position: relative;
    display: block;
    width: 60px;
    height: 60px;
    margin-top: -1px;
    background-color: #fff;
    // border: 1px solid #f5f5f5;
    text-align: center;
    padding-top: 5px;
    .icon {
      position: relative;
      width: 22px;
      height: 22px;
      margin: 0 auto 8px;
      padding-top: 10px;
      img {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 22px;
        height: 22px;
        -webkit-transition: opacity 0.3s;
        transition: opacity 0.3s;
      }
      .static {
        opacity: 1;
      }
      .hover {
        opacity: 0;
      }
    }
    .text {
      color: #757575;
      -webkit-transition: color 0.3s;
      transition: color 0.3s;
    }
    .pop-content {
      position: absolute;
      left: 0;
      top: 0;
      padding: 14px;
      background: #fff;
      border: 1px solid #f5f5f5;
      -webkit-transition: opacity 0.3s;
      transition: opacity 0.3s;
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
      opacity: 0;
      visibility: hidden;
      img {
        display: block;
        width: 100px;
        height: 100px;
        margin: 6px auto;
      }
      .desc {
        display: block;
        width: 120px;
        margin: 14px auto 0;
        color: #757575;
        text-align: center;
      }
    }
    .pop-content::before {
      content: "";
      position: absolute;
      top: 21%;
      width: 0;
      height: 0;
      border-width: 10px;
      border-style: solid;
      overflow: hidden;
      right: -19px;
      border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #f5f5f5;
      z-index: 1;
    }
    .pop-content::after {
      content: "";
      position: absolute;
      top: 21%;
      width: 0;
      height: 0;
      border-width: 10px;
      border-style: solid;
      overflow: hidden;
      right: -18px;
      border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #fff;
      z-index: 2;
    }
  }
  .item1:hover {
    background: #2d74f8;
  }
  .item1:hover .text {
    color: #fff;
  }
  .item1:hover .static {
    opacity: 0;
  }
  .item1:hover .hover {
    opacity: 1;
  }
  //  .item1:hover .mobile{
  //    background: #2d74f8;
  //    .desc{
  //      color: #fff;
  //    }
  //  }
  .item-image:hover .pop-content {
    visibility: visible;
    opacity: 1;

    transform: translate3d(-110%, 0, 0);
  }
}
.backtop {
  margin-top: 14px !important;
  border-top: 1px solid #f5f5f5 !important;
  visibility: hidden;
}
</style>