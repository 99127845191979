import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

//全局css
import './assets/css/gloab.css'
import './assets/font/iconfont.css'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import VueAwesomeSwiper from 'vue-awesome-swiper'
 
//引入样式
import 'swiper/css/swiper.css'
 
Vue.use(VueAwesomeSwiper, /* { 全局组件的默认选项 } */)

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')