export default {
    // 作用在家电的切换热门和电影影音部分（没记错的话）
    showOne(state) {
        console.log(state);
        state.isActive = true
        state.isActive0 = false
        state.isActive_Bottom = true
    },
    showTwo(state) {
        console.log(state);

        state.isActive = false
        state.isActive0 = true
        state.isActive_Bottom = false
            // console.log('i am right',this.isActive_Bottom);
    },
    switchshowMessage(state) {
        state.showMessage = !state.showMessage
    },
    closeMessage(state) {
        state.showMessage = false;
    },
    swicthTab(state, val) {
        localStorage.setItem('swicthTabIndex', val);
        state.tabBarIndex = val;
    },
}