<template>
  <div class="homePageContent">
    <div class="right-detail-content">
      <div class="detail-content">
        <div>
          <div class="right-content-title">
            {{ articleDetail.name }}
          </div>
        </div>
 <div
          style="margin-bottom: 65px; color: #575757; font-size: 16px"
          v-html="articleDetail.introduce"
        ></div>
        <div>
          <img :src="articleDetail.image" alt="" />
        </div>
        <div
          style="margin-bottom: 65px; color: #575757; font-size: 16px"
          v-html="articleDetail.content"
        ></div>
      </div>

      <div style="margin-bottom: 40px">
        <el-button
          style="color: #fff; font-size: 18px; background-color: #2878ff"
          @click="back"
          >返回首页</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { product_intro_detail } from "../../api/index";
export default {
  name: "homepage",
  components: {},
  data() {
    return {
      articleDetail: {},
      id: "",
    };
  },
  mounted() {
    console.log(this.$route.params, "123");
    this.id = this.$route.params.id;
    this.init();
  },
  methods: {
    init() {
      let data = {
        id: this.id,
      };
      product_intro_detail(data).then((res) => {
        console.log(res, "12312312");
        this.articleDetail = res.data;
      });
    },
    back(){
        window.history.back(-1); 
    },
  },
};
</script>

<style lang="less" scoped>
.homePageContent {
  width: 1300px;
  margin: 0 auto;
  .right-content-title {
  margin-bottom: 20px;
  font-size: 28px;
  color: #333333;
  font-weight: 800;
}
  .right-detail-content {
  padding: 60px 30px 45px 30px;
  flex: 1;
}
.detail-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.right-content-card {
  display: flex;
  padding: 30px 50px 30px 30px;
  margin-bottom: 30px;
  cursor: pointer;
}
}

</style>