import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '@/views/Index'

import routerboy0 from '@/views/home/homePage0.vue'
import routerboy1 from '@/views/home/homePage1.vue'
import routerboy2 from '@/views/home/homePage2.vue'
import routerboy3 from '@/views/home/homePage3.vue'
import routerboy4 from '@/views/home/homePage4.vue'

import homePageContent from '@/views/home/homePageContent.vue'

Vue.use(VueRouter)

const routes = [
    { path: '/', redirect: '/Index/routerboy0' },
    {
        path: '/Index',
        component: Index,
        redirect: '/Index/routerboy0',
        children: [{
            path: "routerboy0",
            name: "routerboy0",
            component: routerboy0
        }, {
            path: "routerboy1",
            name: "routerboy1",
            component: routerboy1
        }, {
            path: "routerboy2",
            name: "routerboy2",
            component: routerboy2
        }, {
            path: "routerboy3",
            name: "routerboy3",
            component: routerboy3
        }, {
            path: "routerboy4",
            name: "routerboy4",
            component: routerboy4
        }, {
            path: "homePageContent",
            name: "homePageContent",
            component: homePageContent
        }, ],
    },
]

const router = new VueRouter({
    mode: 'hash',
    routes
})

export default router